/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@emotion/react';

// import p1 from 'assests/Photos/knowmore/History/history.png';


const Form = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Typography
          variant="h4"
          align = 'center'
          sx={{
            fontWeight: 700,
          }}
        >
          Leadership
        </Typography>
        <br />
        <br/>
        <Typography variant='h5' component={'p'} align='justify'  fontWeight={700} >
        Leadership & management<br/>
        </Typography>
        <Typography  component={'p'} align='justify'>
        <br/>At National Public School Yeshwanthpur, we have a team of extraordinary professionals piloting us on our journey of moulding generations of children to excel in a new world through new ways of learning. 
        <br/><br/>Our School Management leads by example: they are changemakers; a magnificent set of professionals and entrepreneurs who are lifelong learners, with a belief in collaboration, innovation and a culture instilled in value based learning that goes beyond the academic paradigm.  
        <br/><br/>Our Chairman, Dr Shiva Shankar is a dynamic leader, a visionary and a strong believer of quality education.  His vision for education promises robust, lifelong development of the individual.  He strongly believes that every individual has the ability to do good and that every good deed has a ripple effect that influences the community in the positive stride. He believes that individuality is to be celebrated, everyone is capable of being a lifelong learner; and everyone has the potential to achieve their life’s ambition, while contributing to making the world a better place. These beliefs directly translate into the Core Values of National Public School Yeshwanthpur.
        <br/><br/>Our Director of I-5 Academy, Dr Manjula Shivashankar believes in an educational system which is balanced, holistic and deeply rooted in Indian culture and values for the all round development of students. She wishes to provide a transformative learning experience for gen-smart learners that helps to inculcate proactive patriotism, loyalty to the motherland and one that is steady in cultural and ethical values. She also believes  that education should create open minded, sensitised lifelong learners, who are prepared to face the challenges of life in the 21st century with confidence and resilience. She is a driving force in the school and is passionate about working collaboratively with learners, teachers and parents as equal partners in the field of education and endeavours to make learning a truly meaningful and happy experience for learners. 
        <br/><br/>Ms Sheeja Manoj, Principal is deeply passionate about equipping the staff with progressive pedagogy and skill sets so learners have meaningful learning experiences. Her focus is to enable National Public School Yeshwanthpur to be a learning organisation where teachers and learners are excited by learning and take ownership for their own development. She is committed to reimagining education to equip children with lifelong skills and also empowering teachers to change their practice to suit the needs of an ever-changing world. Along with academics, her areas of responsibility at National Public School Yeshwanthpur are multiple, ranging from being a pedagogical leader to school administrator. 
       <br/><br/>Our layers of managers include the Assistant Principals, Coordinators, HODs and Teacher In-Charges. The team of managers, leading the workforce of more than 150 people ensure error-free operations and immaculate execution of strategies. With unparalleled commitment and dedication, they oversee the operation of the academic and administrative departments. They share the vision of the institution and inject energy, positivity and enthusiasm into their team members while directing and enabling them to perform by leveraging their highest potential.
        </Typography>
        <Typography variant='h5' component={'p'} fontWeight={700} >
        <br/>Our Faculty<br/>
        </Typography>
        <Typography  component={'p'} align='justify'>
        <br/><br/>We have well experienced faculty with their outstanding performance who support liberal learning approach, which would help the children in critical and lateral thinking along with independence of mind.
        <br/><br/>We have teachers who are well qualified, trained and experienced with right attitude and values, having maturity over the content of their subject.
        <br/><br/>The teachers are classroom managers and facilitators who are always available to guide and to council the students even after school hours.
        <br/><br/>We train the faculty, time to time to update with the latest and most appropriate methods in teaching and learning. 
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
